<template>
  <transition appear name="fade">
    <v-row align="center" justify="center">
      <v-col v-for="item in items" :key="item.id" cols="12" sm="4" md="3">
        <v-hover v-slot:default="{ hover }">
          <router-link style="text-decoration: none" :to="item.path">
            <v-card :elevation="hover ? 3 : 20" class="mx-auto">
              <v-img :src="item.imageSrc" />
              <v-card-title style="word-break: normal" v-text="item.title" />
              <v-card-text class="text-justify" v-text="item.shortDescription" />
            </v-card>
          </router-link>
        </v-hover>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
export default {
  name: 'ListItems',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="stylus">
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
